.card {
    margin: 2rem;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background: slategrey;
    color: white;
}

.avatar {
    background: snow;
}

.username {
    text-align: center;
    margin: 0;
    padding: 0.5rem;
}