html,
body {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

div.users {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 6rem;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 425px) {
  div.users {
    padding-top: 9rem;
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
